import { createRouter, createWebHashHistory } from 'vue-router'
import Day from '../views/Day.vue'
import Week from '../views/Week'
import Month from '../views/Month'
import EndOfDay from '../views/EndOfDay'
const routes = [
  {
    path: '/',
    redirect: '/day'
  },
  {
    path: '/day',
    name: 'Day',
    component: Day
  },
  {
    path: '/month',
    name: 'Month',
    component: Month
  },
  {
    path: '/week',
    name: 'Week',
    component: Week
  },
  {
    path: '/finish',
    name: 'EndOfDay',
    component: EndOfDay
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
