<template>
  <div class="bg-primary flex justify-around p-4">
    <img :src="brand" height="32"  class="max-h-8" alt="Logo van MediaMere">
    <div class="mt-0.5">
      <p class="text-white font-medium text-lg">{{ getTranslatedName(this.$route.name)}} modus</p>
    </div>
    <div class="flex mt-0.5">
      <router-link to="/day" class="text-white font-medium text-lg mx-2">Dag</router-link>
      <router-link to="/week" class="text-white font-medium text-lg mx-2">Week</router-link>
      <router-link to="/month" class="text-white font-medium text-lg mx-2">Maand</router-link>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/logo.png'

export default {
  name: 'Navbar',
  data () {
    return {
      brand: logo
    }
  },
  methods: {
    getTranslatedName (name) {
      switch (name) {
        case 'Day':
          return 'Dag'
        case 'Week':
          return 'Week'
        case 'Month':
          return 'Maand'
        case 'EndOfDay':
          return 'Finish'
      }
    }
  }
}
</script>

<style scoped>

</style>
