import { createStore } from 'vuex'
import http from '@/composables/http-commen'
import { ref } from 'vue'

export default createStore({
  state: {
    workingHours: ref({}),
    contractHours: []
  },
  getters: {
    runners: state => {
      const runners = []
      for (const [index] of Object.entries(state.workingHours)) {
        runners.push({
          name: index.replaceAll('_', ' '),
          working_hours: state.workingHours[index],
          contact_hours: state.contractHours[index]
        })
      }
      return runners
    }
  },
  mutations: {
    addWorkHours (state, payload) {
      console.log('fired')
      const workingHours = state.workingHours[payload.name.replaceAll(' ', '_').toLowerCase()]
      if (workingHours) {
        console.log('added to already dinges')
        // Vue.set(state.workingHours, payload.name.replaceAll(' ', '_').toLowerCase(), workingHours + (payload.minutes / 60))
        state.workingHours[payload.name.replaceAll(' ', '_').toLowerCase()] = workingHours + (payload.minutes / 60)
      } else {
        console.log('new record')
        // Vue.set(state.workingHours, payload.name.replaceAll(' ', '_').toLowerCase(), payload.minutes / 60)
        state.workingHours[payload.name.replaceAll(' ', '_').toLowerCase()] = payload.minutes / 60
      }
    },
    resetHours (state) {
      state.workingHours = []
      state.contractHours = []
    }
  },
  actions: {
    async collectWorkedHours ({ commit, state }, payload) {
      console.log('collecting worked hours')
      commit('resetHours')

      // Uren ophalen van MediaMere
      const response = await http.get(process.env.VUE_APP_MEDIAMERE_URL + '/hours', {
        headers: {
          Authorization: 'Bearer ' + process.env.VUE_APP_MEDIAMERE_TOKEN
        }
      })
      const data = await response.data
      await data.forEach(user => {
        state.contractHours[user.name.replaceAll(' ', '_').toLowerCase()] = user.contract_hours
        commit('addWorkHours', {
          name: user.name,
          minutes: user.total_minutes
        })
      })
      console.log(state.workingHours)
    }
  }
})
