<template>
  <div class="min-w-full bg-track border-b-2 border-t-2 border-green-800 flex justify-start px-4 py-2 flex-auto">
    <p class="text-white mt-5">{{ runner.name }}</p>
    <input type="range" name="uren" :value="getPercentage(runner)" disabled
           min="0" max="100"
           :class="'w-full appearance-none bg-track ' + runner.name.toLowerCase().replaceAll(' ', '_')">
  </div>
</template>

<script>
export default {
  name: 'Runner',
  props: {
    runner: Object
  },
  methods: {
    getPercentage (runner) {
      if (!runner.contract_hours) {
        return 50
      }
      switch (this.$router.currentRoute.value.name) {
        case 'Month':
          return runner.hours / (runner.contract_hours * 4) * 100
        case 'Week':
          console.log(runner.name + ':' + runner.hours)
          return runner.hours / runner.contract_hours * 100
        case 'Day':
          return runner.hours / 7.15 * 100
        default:
          return runner.hours / runner.contract_hours * 100
      }
    }
  }
}
</script>

<style scoped>
.silke_honing::-webkit-slider-thumb {
  width: 100px;
  -webkit-appearance: none;
  appearance: none;
  height: 100px;
  cursor: ew-resize;
  background-image: url('/silke_honing.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.ingo_sliphorst::-webkit-slider-thumb {
  width: 100px;
  -webkit-appearance: none;
  appearance: none;
  height: 100px;
  cursor: ew-resize;
  background-image: url('/ingo_sliphorst.png ');
  background-size: contain;
  background-repeat: no-repeat;
}

.martijn_de_vries::-webkit-slider-thumb {
  width: 100px;
  -webkit-appearance: none;
  appearance: none;
  height: 100px;
  cursor: ew-resize;
  background-image: url('/martijn_de_vries.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.daniël_van_der_spoel::-webkit-slider-thumb {
  width: 100px;
  -webkit-appearance: none;
  appearance: none;
  height: 100px;
  cursor: ew-resize;
  background-image: url('/daniel_van_der_spoel.png ');
  background-size: contain;
  background-repeat: no-repeat;
}

.chang_sie_blanker::-webkit-slider-thumb {
  width: 100px;
  -webkit-appearance: none;
  appearance: none;
  height: 100px;
  cursor: ew-resize;
  background-image: url('/chang_sie_blanker.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.eline_van_alebeek::-webkit-slider-thumb {
  width: 100px;
  -webkit-appearance: none;
  appearance: none;
  height: 100px;
  cursor: ew-resize;
  background-image: url('/eline_van_alebeek.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.justin_hemler::-webkit-slider-thumb {
  width: 100px;
  -webkit-appearance: none;
  appearance: none;
  height: 100px;
  cursor: ew-resize;
  background-image: url('/justin_hemler.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.eindbaas_martijn::-webkit-slider-thumb {
  width: 100px;
  -webkit-appearance: none;
  appearance: none;
  height: 100px;
  cursor: ew-resize;
  background-image: url('/eindbaas_martijn.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.kevin_bouwens::-webkit-slider-thumb {
  width: 100px;
  -webkit-appearance: none;
  appearance: none;
  height: 100px;
  cursor: ew-resize;
  background-image: url('/kevin_bouwens.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.thomas_diemel::-webkit-slider-thumb {
  width: 100px;
  -webkit-appearance: none;
  appearance: none;
  height: 100px;
  cursor: ew-resize;
  background-image: url('/thomas_diemel.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.trevor_steele::-webkit-slider-thumb {
  width: 100px;
  -webkit-appearance: none;
  appearance: none;
  height: 100px;
  cursor: ew-resize;
  background-image: url('/trevor_steele.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.tobias_van_der_ploeg::-webkit-slider-thumb {
  width: 100px;
  -webkit-appearance: none;
  appearance: none;
  height: 100px;
  cursor: ew-resize;
  background-image: url('/tobias_van_der_ploeg.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.davide_van_den_bosch::-webkit-slider-thumb {
  width: 100px;
  -webkit-appearance: none;
  appearance: none;
  height: 100px;
  cursor: ew-resize;
  background-image: url('/davide_van_den_bosch.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.lucas_hoogerbrugge::-webkit-slider-thumb {
  width: 100px;
  -webkit-appearance: none;
  appearance: none;
  height: 100px;
  cursor: ew-resize;
  background-image: url('/lucas_hoogerbrugge.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.myrthe_sierksma::-webkit-slider-thumb {
  width: 100px;
  -webkit-appearance: none;
  appearance: none;
  height: 100px;
  cursor: ew-resize;
  background-image: url('/myrthe_sierksma.png');
  background-size: contain;
  background-repeat: no-repeat;
}

</style>
