<template>
    <div class="flex justify-center">
      <div class="bg-white rounded-md m-10 p-4 w-1/2">
          <div class="flex justify-center">
            <h1 class="font-bold text-xl">De dag is afgelopen!</h1>
          </div>
          <div class="mt-20 flex justify-center">
            <div class="w-1/3 mt-10" v-if="runners[1]">
              <div>
                <div class="flex justify-center">
                  <img :src="'/' + runners[1].name.toLowerCase().replaceAll(' ', '_').replaceAll('ë', 'e') + '.png'" style="max-height: 125px">
                </div>
                <div class="text justify-center">
                  <p>{{ runners[1].name }}</p>
                  <p class="italic">2e ({{ runners[1].hours.toFixed(2).replace('.', ',') }} uur)</p>
                </div>
                <div class="h-14" style="background-color: #C4CACE"></div>
              </div>
            </div>
            <div class="w-1/3" v-if="runners[0]">
              <div>
                <div class="flex justify-center">
                  <img :src="'/' + runners[0].name.toLowerCase().replaceAll(' ', '_').replaceAll('ë', 'e') + '.png'" style="max-height: 125px">
                </div>
                <div class="text justify-center">
                  <p>{{ runners[0].name }}</p>
                  <p class="italic">1e ({{ runners[0].hours.toFixed(2).replace('.', ',') }} uur)</p>
                </div>
                <div class="h-24" style="background-color: #D4Af37"></div>
              </div>
            </div>
            <div class="w-1/3 mt-16" v-if="runners[2]">
              <div>
                <div class="flex justify-center">
                  <img :src="'/' + runners[2].name.toLowerCase().replaceAll(' ', '_').replaceAll('ë', 'e') + '.png'" style="max-height: 125px">
                </div>
                <div class="text justify-center">
                  <p>{{ runners[2].name }}</p>
                  <p class="italic">3e ({{ runners[2].hours.toFixed(2).replace('.', ',') }} uur)</p>
                </div>
                <div class="h-8" style="background-color: #CD7F32"></div>
              </div>
            </div>
          </div>
      </div>
    </div>
</template>

<script>
import confetti from 'canvas-confetti'
import { DateTime } from 'luxon'
import useRunners from '@/modules/runners'

export default {
  name: 'EndOfDay',
  async setup () {
    // calculate winners
    const now = DateTime.now().toFormat('y-MM-dd')
    const { runners, load } = useRunners()
    await load(now, now)

    await runners.value.sort((runnerA, runnerB) => {
      if (runnerA.hours > runnerB.hours) {
        return -1
      }
      if (runnerA.hours < runnerB.hours) {
        return 1
      }
      // a must be equal to b
      return 0
    })
    await console.log(runners.value)
    // create confettie
    const colors = ['#bb0000', '#ffffff']
    setInterval(function () {
      confetti({
        particleCount: 20,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: colors
      })
      confetti({
        particleCount: 20,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: colors
      })
    }, 500)

    return { runners }
  }
}
</script>

<style scoped>
.daniel {
  background-image: url('/daniel_van_der_spoel.png');
}
</style>
