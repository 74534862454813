<template>
  <navbar></navbar>
  <div class="m-10 grass">
    <suspense>
      <template #default>
        <router-view/>
      </template>
      <template #fallback>
        <div>
          Loading...
        </div>
      </template>
    </suspense>

  </div>
</template>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
<script>
import Navbar from './components/Navbar'
import { useRouter } from 'vue-router'
export default {
  components: { Navbar },
  setup () {
    const router = useRouter()
    setInterval(function () {
      const now = new Date()
      if (now.getHours() === 17) {
        router.push({
          name: 'EndOfDay'
        })
      }
    }, 10 * 1000)
  }
}
</script>
