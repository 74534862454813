import { ref } from 'vue'
import http from '@/composables/http-commen'

export default function useRunners () {
  const runners = ref([])

  const load = async (fromDate, toDate) => {
    try {
      runners.value = []
      console.log(fromDate, toDate)
      const responseMediamere = await http.get(process.env.VUE_APP_MEDIAMERE_URL + '/hours?from=' + fromDate + '&to=' + toDate, {
        headers: {
          Authorization: 'Bearer ' + process.env.VUE_APP_MEDIAMERE_TOKEN
        }
      })
      const dataMediamere = await responseMediamere.data

      await dataMediamere.forEach(user => {
        console.log(user)
        runners.value.push({
          name: user.name,
          hours: user.total_minutes / 60,
          contract_hours: user.contract_hours
        })
      })

      const responseTechmere = await http.get(process.env.VUE_APP_TECHMERE_URL + '/hours?from=' + fromDate + '&to=' + toDate, {
        headers: {
          Authorization: 'Bearer ' + process.env.VUE_APP_TECHMERE_TOKEN
        }
      })
      const dataTechmere = await responseTechmere.data

      await dataTechmere.forEach(user => {
        const runner = runners.value.find(runner => runner.name === user.name)
        if (runner) {
          runner.hours += user.total_minutes / 60
          runner.contract_hours = user.contract_hours
        } else {
          runners.value.push({
            name: user.name,
            hours: user.total_minutes / 60,
            contract_hours: user.contract_hours
          })
        }
      })
    } catch (e) {
      console.log(e)
    }
  }
  return { runners, load }
}
