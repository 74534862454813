<template>
  <suspense>
    <template #default>
      <Track :from_date="from" :to_date="to" />
    </template>
    <template #fallback>
      <div>
        Loading...
      </div>
    </template>
  </suspense>
</template>

<script>
import Track from '../components/Track'
import { DateTime } from 'luxon'

export default {
  name: 'Month',
  components: { Track },
  setup () {
    const datetime = DateTime.fromObject({
      year: DateTime.now().year,
      month: DateTime.now().month
    })
    const from = datetime.startOf('month').toFormat('y-MM-dd')
    const to = datetime.endOf('month').toFormat('y-MM-dd')
    return { from, to }
  }
}
</script>

<style scoped>

</style>
