<template>
  <div class="flex">
    <div class="flex-grow my-10">
        <Runner v-for="runner in runners" v-bind:key="runner.name" :runner="runner"></Runner>
    </div>
    <div class="finish">
    </div>
  </div>

</template>

<script>
import useRunners from '@/modules/runners'
import Runner from './Runner'
import router from '@/router/index'

export default {
  name: 'Track',
  components: { Runner },
  props: {
    from_date: String,
    to_date: String
  },
  async setup (props) {
    const { runners, load } = useRunners()
    await load(props.from_date, props.to_date)

    setInterval(async function () {
      const now = new Date()
      console.log(now.getHours())
      if (now.getHours() === 17) {
        await router.push('finish')
      }
      await load(props.from_date, props.to_date)
    }, 60 * 1000)
    return {
      runners
    }
  }
}
</script>

<style scoped>
.finish {
  width: 100px;
  background-size: 435px 1175px;
  background-image: url('/finish.png');
}
</style>
